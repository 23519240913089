import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { Link, graphql } from "gatsby";
import { kebabCase } from "lodash";

class BlogRoll extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const { currentPage, numPages } = pageContext;

    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? "/" : `/page/${currentPage - 1}`;
    const nextPage = `/page/${currentPage + 1}`;

    return (
      <Layout>
        <div>
          {posts &&
            posts.map(({ node: post }) => (
              <div className="is-parent column is-6" key={post.id}>
                <article
                  className={`blog-list-item tile is-child box notification`}
                >
                  <header>
                    <h2 className="entry-title">
                      <Link
                        className="title has-text-primary is-size-4"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>
                    </h2>
                    <div className="entry-meta">
                      Posted on{" "}
                      <Link to={post.fields.slug}>{post.frontmatter.date}</Link>
                    </div>
                  </header>
                  <HTMLContent content={post.html} />
                  {post.frontmatter.tags && post.frontmatter.tags.length ? (
                    <div className="entry-meta">
                      Posted in{" "}
                      {post.frontmatter.tags.map((tag, index) => (
                        <span>
                          <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          {index === post.frontmatter.tags.length - 2
                            ? " and "
                            : ""}
                          {index < post.frontmatter.tags.length - 2 ? ", " : ""}
                        </span>
                      ))}
                      {"."}
                    </div>
                  ) : null}
                </article>
              </div>
            ))}
        </div>
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Previous Page
          </Link>
        )}
        {!isLast && (
          <Link to={nextPage} rel="next">
            Next Page →
          </Link>
        )}
      </Layout>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default BlogRoll;

export const query = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
  }
`;
